import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  MerchantChangeService,
  NotificationService,
  StaticHelpers,
} from '@odin/odin-core';
import {
  IFormattedTransaction,
  ITransaction,
  TransactionRefund,
  TransactionStatus,
} from '../transactions-layout/models';
import {
  faBug,
  faCircleQuestion,
  faGlobe,
  faHeadset,
  faLink,
  faRepeat,
  faRotateLeft,
  faStore,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  TransactionFormatterService,
  TransactionsService,
} from '../transactions-layout/services';
import { AccessGuardService } from '@odin/odin-authentication';
import { RechargeTransactionModalComponent, RechargeModalData } from '../transactions-layout/components/recharge-transaction-modal/recharge-transaction-modal.component';
import { RefundTransactionModalComponent, RefundModalData } from '../transactions-layout/components/refund-transaction-modal/refund-transaction-modal.component';
import { ReverseTransactionModalComponent, ReverseModalData } from '../transactions-layout/components/reverse-transaction-modal/reverse-transaction-modal.component';
import { TransactionRecharge } from '../transactions-layout/models/transaction-recharge';
import { MatDialog } from '@angular/material/dialog';
import { ReceiptDetail } from '../print-receipt/print-receipt.component';

export class TransactionTimelineEntry {
  constructor(
    public status: boolean,
    public title: string,
    public time: Date,

    public transaction: IFormattedTransaction,
  ) { }
}

@Component({
  standalone: false,
  selector: 'odin-transaction-detail-page',
  templateUrl: './transaction-detail-page.component.html',
  styleUrl: './transaction-detail-page.component.css',
})
export class TransactionDetailPageComponent implements OnInit {
  public loading: boolean = true;
  private transactionTraceId: string;
  public transactionType: string = 'Transaction';
  public transaction: WritableSignal<IFormattedTransaction> =
    signal<IFormattedTransaction>({} as IFormattedTransaction);
  public relatedTransactions: WritableSignal<IFormattedTransaction[]> = signal<
    IFormattedTransaction[]
  >([]);
  public relatedLoading: WritableSignal<boolean> = signal<boolean>(false);

  public timelineEvents: TransactionTimelineEntry[] = [];
  public shelper = StaticHelpers;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private accessService: AccessGuardService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionsService,
    private transactionFormatter: TransactionFormatterService,
    private merchantChangeService: MerchantChangeService
  ) {
  }

  ngOnInit(): void {
    this.merchantChangeService.onChange('transaction detail page', (buid: string) => {
      // transactions not available on different buid, so back out to transactions
      this.router.navigate(['transactions']);
    });
    this.transactionTraceId = this.activatedRoute.snapshot.params['traceId'];
    this.refreshTransaction();
  }

  public refreshTransaction(): void {
    this.loading = true;
    this.relatedTransactions.set([]);
    this.transaction.set({} as IFormattedTransaction);
    this.fetchTransactionDetail();
  }

  private fetchTransactionDetail(): void {
    this.loading = true;
    this.transactionService
      .getTransaction(this.transactionTraceId)
      .subscribe((t: ITransaction) => {
        this.transaction.set(this.transactionFormatter.formatTransaction(t));
        this.transactionType = this.GetTransactionType(t);
        this.loading = false;

        this.getRelated();
      });
  }

  public GetTransactionType(transaction: ITransaction): string {
    return transaction.transactionType === 'refund'
      ? 'Refund'
      : transaction.isReversed
        ? 'Reversal'
        : 'Transaction';
  }

  public isSuccess(
    transaction: ITransaction,
    reverseIsSuccess: boolean = false,
  ): boolean {
    if (transaction.status === TransactionStatus.Succeeded) return true;
    if (reverseIsSuccess && transaction.status === TransactionStatus.Reversed)
      return true;
    return false;
  }

  public transactionIcon(transaction: ITransaction): IconProp {
    if (transaction.transactionType == 'virtual_terminal') return faHeadset;
    if (transaction.transactionType == 'pay_by_link') return faLink;
    if (transaction.transactionType == 'ecommerce') return faGlobe;
    if (transaction.transactionType == 'refund') return faRotateLeft;
    if (transaction.channel == 'recurring') return faRepeat;
    if (transaction.channel == 'ecommerce') return faGlobe;
    if (transaction.channel == 'moto') return faHeadset;
    if (transaction.channel == 'cardholder_present') return faStore;
    return faCircleQuestion;
  }

  public showRefundAmount(): boolean {
    if (this.transaction().amountRefunded !== undefined) return false;
    const amountRefunded = this.transaction().amountRefunded;
    if (amountRefunded === undefined) return false;
    if (amountRefunded <= 0) return false;
    return true;
  }

  public showPaymentDescription(): boolean {
    if (this.transaction().paymentDetail === undefined) return false;
    if (this.transaction().paymentDetail === null) return false;
    if (this.transaction().paymentDetail.length === 0) return false;
    return true;
  }

  private getRelated(): void {
    this.relatedLoading.set(true);
    this.transactionService
      .GetRelatedTransactions(this.transaction())
      .subscribe(
        (res: ITransaction[]) => {
          this.relatedTransactions.set(
            res.map((t: ITransaction) => {
              return this.transactionFormatter.formatTransaction(t);
            }),
          );

          this.relatedTransactions().forEach((t: IFormattedTransaction) => {
            if (t.transactionType !== 'refund') return;
            if (t.status === 'declined') return;
            if (this.transaction === undefined) return;
            this.transaction.update((value: IFormattedTransaction) => {
              if (value.amountRefunded == undefined) value.amountRefunded = 0;
              if (value.remainingValue == undefined) value.remainingValue = 0;

              value.remainingValue = value.remainingValue - t.amount;
              value.amountRefunded = value.amountRefunded + t.amount;
              return value;
            });
          });

          this.relatedTransactions.set(
            this.relatedTransactions().sort(
              (a, b) =>
                new Date(a.createdOn).getTime() -
                new Date(b.createdOn).getTime(),
            ),
          );

          this.relatedLoading.set(false);
          this.createTimeline();
        },
        () => {
          this.relatedLoading.set(false);
          this.notificationService.SmallDialog(
            'Failed to fetch related transactions.',
          );
          this.createTimeline();
        },
      );
  }

  public canPrint(): boolean {
    if (this.transaction().status === TransactionStatus.Cancelled)
      return false;
    return true;
  }

  public isTransactDay(): boolean {
    // check happened TODAY before 23:59pm
    const transactionTime = new Date(this.transaction().createdOn);
    const thisMorning = new Date(Date.now());
    thisMorning.setHours(0);
    thisMorning.setMinutes(0);
    thisMorning.setSeconds(0);
    thisMorning.setMilliseconds(0);
    const tomorrow = new Date(thisMorning);
    tomorrow.setHours(23);
    tomorrow.setMinutes(59);
    tomorrow.setSeconds(59);
    if (transactionTime > thisMorning && transactionTime < tomorrow)
      return false;
    return true;
  }

  public canReverse(): boolean {
    if (!this.accessService.hasGrant('txn:Reverse'))
      return this.LogAndReturn('No txn:Reverse Grant', false);
    if (this.transaction().transactionType === 'refund')
      return this.LogAndReturn('Is Refund', false);
    if (this.transaction().isReversed)
      return this.LogAndReturn('Is Reversed', false);
    if (this.transaction().isReversal)
      return this.LogAndReturn('Is Reversal', false);
    if (this.transaction().status === TransactionStatus.Cancelled)
      return this.LogAndReturn('Is Cancelled', false);
    if (this.isTransactDay())
      return this.LogAndReturn('Is Transact Day', false);
    if (this.relatedLoading()) return false;
    return true;
  }

  public canRefund(): boolean {
    if (!this.accessService.hasGrant('txn:Refund'))
      return this.LogAndReturn('No txn:Refund Grant', false);
    if (this.transaction().transactionType === 'refund')
      return this.LogAndReturn('Is Refund', false);
    if (this.transaction().isReversed)
      return this.LogAndReturn('Is Reversed', false);
    if (this.transaction().isReversal)
      return this.LogAndReturn('Is Reversal', false);
    if (this.transaction().status === TransactionStatus.Cancelled)
      return this.LogAndReturn('Is Cancelled', false);
    if (this.relatedLoading()) return false;
    return true;
  }

  public canReCharge(): boolean {
    if (!this.accessService.hasGrant('txn:Recharge'))
      return this.LogAndReturn('No txn:Recharge Grant', false);
    if (this.transaction().transactionType === 'refund')
      return this.LogAndReturn('Is Refund', false);
    if (this.transaction().isReversed)
      return this.LogAndReturn('Is Reversed', false);
    if (this.transaction().isReversal)
      return this.LogAndReturn('Is Reversal', false);
    if (this.transaction().status === TransactionStatus.Cancelled)
      return this.LogAndReturn('Is Cancelled', false);
    if (!this.isTransactDay())
      return this.LogAndReturn('Is Transact Day', false);
    if (this.relatedLoading()) return false;
    return true;
  }

  private createTimeline(): void {
    // add open transaction
    this.timelineEvents = [];
    this.timelineEvents.push(
      new TransactionTimelineEntry(
        this.isSuccess(this.transaction()),
        (!this.isSuccess(this.transaction()) ? 'Declined ' : 'Completed ') + this.transaction().formattedType,
        this.transaction().createdOn,
        this.transaction(),
      ),
    );

    // add in related
    this.timelineEvents.push(
      ...this.relatedTransactions().map((related: IFormattedTransaction) => {
        return new TransactionTimelineEntry(
          this.isSuccess(related, true),
          related.formattedType,
          related.createdOn,
          related,
        );
      }),
    );

    this.timelineEvents.push();

    this.timelineEvents = this.timelineEvents.sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
    );
  }

  public OpenRelation(_transaction: IFormattedTransaction): void {
    this.router.navigate([`transactions/detail/${_transaction.crossReference}`]);
  }

  public BackButtonHandle(): void {
    this.router.navigate(['transactions']);
  }


  //

  public getStackTrace(): string {
    try {
      throw new Error('');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.stack || '';
    }
  }

  public LogAndReturn(
    msg: string,
    toReturn: boolean,
    logLevel: number = 0,
  ): boolean {
    if (window._audit_logs == undefined) window._audit_logs = [];
    window._audit_logs.push({
      level: logLevel,
      log: msg,
      stack: this.getStackTrace(),
    });
    return toReturn;
  }

  public createReceipt(): ReceiptDetail {
    return new ReceiptDetail(
      '',
      this.transaction().crossReference,
      this.transaction().cardHolderDetails !== undefined ? this.transaction().cardHolderDetails.name ||
        this.transaction().maskedCard : '',
      this.transaction().paymentReference,
      this.transaction().createdOn,
      '',
      this.transaction().amount,
      this.transaction().currencyCountry,
    );
  }

  // handlers

  public printReceipt(): void {
    window.print();
  }

  public startRefundRequest(): void {
    const refundRequestModal = this.dialog.open(
      RefundTransactionModalComponent,
      {
        data: new RefundModalData(this.transaction()),
        autoFocus: false,
        panelClass: 'main-panel',
        maxWidth: 400,
      },
    );
    refundRequestModal.afterClosed().subscribe((refund: TransactionRefund) => {
      if (refund == null) return;
      this.refreshTransaction();
    });
  }

  public startRechargeRequest(): void {
    const rechargeRequestModal = this.dialog.open(
      RechargeTransactionModalComponent,
      {
        data: new RechargeModalData(this.transaction()),
        autoFocus: false,
        panelClass: 'main-panel',
        maxWidth: 400,
      },
    );
    rechargeRequestModal
      .afterClosed()
      .subscribe((recharge: TransactionRecharge) => {
        if (recharge == null) return;
        this.refreshTransaction();
      });
  }

  public startReverseRequest(): void {
    const reverseRequestModal = this.dialog.open(
      ReverseTransactionModalComponent,
      {
        data: new ReverseModalData(this.transaction()),
        autoFocus: false,
        panelClass: 'main-panel',
        maxWidth: 400,
      },
    );
    reverseRequestModal.afterClosed().subscribe(() => {
      this.refreshTransaction();
    });
  }
}
